// 
// user.scss
// Use this to write your custom SCSS
//
.table-filter-container {
  font-size: 0.825rem;
  text-transform: none;
  font-weight: normal;
  letter-spacing: normal;
  color: #12263F;
}

.indexedStep {
  color: white;
  width: 20px;
  height: 20px;
  font-size: 12px;
  background-color: rgba(211, 211, 211, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.indexedStep.accomplished {
  background-color: rgba(0, 116, 217, 1);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
  position: absolute;
  bottom: -1.6em;
}

.bold-text {
  font-weight: bold;
}

.border-top-space {
  border-top: 5px solid transparent !important;
}

.border-bottom-space {
  border-bottom: 5px solid transparent !important;
}
