.table-container-inventory {
  height: calc(100vh - 220px);
  overflow: auto;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #ffffff;
}
