.d-a-category-row-active {
  background-color: #fff7cc;
  font-weight: bold;
  color: #a89b2a;
  padding-left: 35px;
  margin-left: -32px;
  margin-right: -32px;
}
.d-a-category-row-open {
  background-color: #eafcec;
  font-weight: bold;
  color: #205906;
  padding-left: 35px;
  margin-left: -32px;
  margin-right: -32px;
}
.d-a-category-row-inactive {
  background-color: #dcdcdc;
  font-weight: bold;
  border-top: 5px solid white;
  color: #474747;
  padding-left: 35px;
  margin-left: -32px;
  margin-right: -32px;
}
.d-a-category-row-close {
  background-color: #fff4f4;
  font-weight: bold;
  border-top: 5px solid white;
  color: #7e1f1f;
  padding-left: 35px;
  margin-left: -32px;
  margin-right: -32px;
}
.d-a-border-left-active {
  width: 16px;
  height: 100%;
  background-color: #fff7cc;
  position: absolute;
  top: 0px;
  left: 0px;
}
.d-a-border-left-open {
  width: 16px;
  height: 100%;
  background-color: #eafcec;
  position: absolute;
  top: 0px;
  left: 0px;
}
.d-a-border-left-inactive {
  width: 16px;
  height: 100%;
  background-color: #dcdcdc;
  position: absolute;
  top: 0px;
  left: 0px;
}
.d-a-border-left-close {
  width: 16px;
  height: 100%;
  background-color: #fff4f4;
  position: absolute;
  top: 0px;
  left: 0px;
}
.table tbody tr {
  height: 16px;
}
.button-group {
  display: flex;
  margin-bottom: 20px;
}

.button-group button {
  border: 1px solid #ccc;
  background-color: #f0f0f0;
  padding: 10px 20px;
  margin-right: 5px;
  cursor: pointer;
  font-size: 14px;
}

.bg-navy {
  background-color: #203a56;
  height: 130px;
}
.sticky-header {
  cursor: pointer;
}
.ub-pst_fixed.ub-top_0px.ub-lft_0px.ub-rgt_0px.ub-btm_0px.ub-z-idx_20.ub-dspl_flex.ub-algn-itms_flex-start.ub-just-cnt_center.ub-bg-clr_hiar9y.ub-lft_1l7b3ar.ub-top_1l7b3ar.ub-pst_i1ezrf.ub-dspl_hlr2nt.ub-w_6x895d.ub-h_6x895d.ub-cnt_115q0rt.ub-a-nm_vks92c.ub-a-dur_sdwjay.ub-a-tmng-fn_4nrifk.ub-a-fill-md_o84xcc.ub-a-nm_vks92c.ub-a-dur_sdwjay.ub-a-tmng-fn_4nrifk.ub-a-fill-md_o84xcc.ub-a-nm_1f55gft.ub-a-dur_sdwjay.ub-a-tmng-fn_1p2lg05.ub-a-fill-md_o84xcc.ub-a-nm_1f55gft.ub-a-dur_sdwjay.ub-a-tmng-fn_1p2lg05.ub-a-fill-md_o84xcc.ub-box-szg_border-box {
  margin-left: 13%;
}
.filter-area {
  height: 25px;
}