.rotate-icon {
  transform: rotate(90deg);
  transition: transform 0.3s ease;
}

.rotate-back {
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.icon-button {
  transition: transform 0.3s ease;
}

.adjustments-list {
  width: 12px;
  margin-right: 5px;
  margin-bottom: 2px;
}
