.edit-delivery-modal .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.edit-delivery-modal .header h2 {
  margin: 0;
  font-size: 24px;
}

.edit-delivery-modal .header p {
  margin: 0;
  font-size: 14px;
  color: #6d6d6d;
}

.edit-delivery-modal .section-header {
  background: #fffae5;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 10px;
  border: 1px solid #e4e7eb;
}

.edit-delivery-modal .form-group {
  margin-bottom: 15px;
}

.edit-delivery-modal .form-group label {
  display: block;
  font-size: 14px;
  color: #6d6d6d;
  margin-bottom: 5px;
}

.edit-delivery-modal .form-group .button {
  width: 100%;
  text-align: left;
}

.edit-delivery-modal .modal-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.edit-delivery-modal .modal-footer button {
  margin-left: 10px;
}
.display-flex {
  display: flex;
  padding: 0px 25px 0px 25px;
}
.display-flex-col-35 {
  width: 35%;
  margin: auto;
}
.display-flex-col-10 {
  width: 10%;
}
.display-flex-col-20 {
  width: 20%;
}
.display-flex-col-40 {
  width: 40%;
}
.display-flex-col-50 {
  width: 50%;
}
.display-flex-col-60 {
  width: 60%;
}
.display-flex-col-100 {
  width: 100%;
}
/* .ub-dspl_flex.ub-ovflw-x_auto.ub-ovflw-y_auto.ub-flx-drct_column.ub-min-h_80px.ub-pb_8px.ub-pt_8px.ub-pl_32px.ub-pr_32px.ub-box-szg_border-box {
  overflow: hidden;
} */
