//
// list.scss
// List.js plugin overrides
//

// Pagination

.page {
  @extend .page-link;
}

.list-pagination > li + li {
  margin-left: -$pagination-border-width;
}


// Alert

.list-alert {
  position: fixed;
  top: map-get($spacers, 3);
  left: 50%;
  z-index: $zindex-fixed;
  min-width: $list-alert-min-width;
  margin-bottom: 0;
  transform: translateX(-50%);
}

.list-alert:not(.show) {
  pointer-events: none;
}

@include media-breakpoint-up($navbar-vertical-expand-breakpoint) {
  .navbar-vertical:not(.navbar-vertical-sm):not([style*="display: none"]) ~ .main-content .list-alert {
    left: calc(50% + #{$navbar-vertical-width / 2});
  }
}

.list-alert .close {
  top: 50%;
  transform: translateY(-50%);
}
