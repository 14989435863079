.button-group {
  display: flex;
  margin-bottom: 20px;
}

.button-group button {
  border: 1px solid #ccc;
  background-color: #f0f0f0;
  padding: 10px 20px;
  margin-right: 5px;
  cursor: pointer;
  font-size: 14px;
}

.d-a-active {
  background-color: #203a56 !important;
  color: white !important;
  border: 1px solid #203a56 !important;
}
